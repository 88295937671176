import { Spinner } from '@chakra-ui/react'
import Layout from '@/components/layout'
import Reports, { useSelectedReportType } from './reports'
import ReportsPage from '@/components/reports_page'

const Web2 = () => {
  const [selectedReportType] = useSelectedReportType()
  if (!selectedReportType) {
    return <Spinner />
  }
  return <ReportsPage isOrgUnifiedView={false} />
}

Web2.getLayout = function getLayout(page) {
  return (
    <Layout>
      <Reports component={page}></Reports>
    </Layout>
  )
}

export default Web2
