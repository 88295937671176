import Layout from '../components/layout'
import Reports from './reports'
import Web2 from './web2'

const Index = () => {
  return <Web2 />
}

Index.getLayout = function getLayout(page) {
  return (
    <Layout>
      <Reports component={page} />
    </Layout>
  )
}

export default Index
